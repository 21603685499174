<template>
  <div>
    <div v-if="tenant.id == 'eventscom'">
      <h1>About EveryChallenge</h1>
      <p>
        EveryChallenge is a Virtual Challenge Platform focused on creating engaging virtual endurance sports experiences. This tool was created to provide a responsible way for participants to enjoy the great outdoors, stay active, and connect. 
      </p>
      <p>
        EveryChallenge is not just another virtual challenge platform - it offers a solution to all kinds of virtual and hybrid events worldwide. With its powerful feature set, including the ability to auto-sync activities, seven different challenge types, customizable badges, and much more, EveryChallenge combines all the tools you need in one platform to host an event that gives participants a frictionless, fun, and gamified experience. 
      </p>

      <h2 class="subtitle">Powered by the <strong>sodisp.com</strong> Challenge Platform</h2>
      <p>
       <a href="https://sodisp.com" target="_blank">sodisp</a> is the most powerful platform to host virtual events and challenges. 
       We offer Strava, Garmin, Polar and Fitbit integration, 6 challenge modes and numerous powerful features to create engaging virtual experiences! 
       <a href="https://sodisp.com" target="_blank">Learn how »</a>
      </p>

      <h2 class="subtitle">Contact us</h2>
      <p>
        We'd love to have your feedback on our website, so feel free to drop us a line.
      </p>

      <p>
        <v-btn color="primary" to="/contact">Contact Us</v-btn>
      </p>
    </div>
    <div v-else>
      <h1>What's in the name</h1>
      <p>
        The name <strong>sodisp</strong> is short for <strong>so</strong>cial <strong>di</strong>stancing <strong>sp</strong>orts.
        We started as a platform to host virtual challenges during the Covid pandemic. 
        Over time our platform has been optimized to host engaging events of all sorts. Whether your event is in-person, hybrid, or virtual, our platform has the features to create a connected and engaging experience for it!
      </p>
      <p>
        Our focus is to create the most fun and engaging experience for your participants. We allow them to keep using the fitness tracker they already use and love. For those users without a fitness tracker we have an easy to use manual entry tool.
      </p>

      <h2 class="subtitle">Our mission:</h2>
      <blockquote class="mb-4">"Make it easier and more fun to be active, and keep it up!"</blockquote>
      <p>We believe we can make it easier and more fun for anyone to live an active lifestyle. We use gamification techniques to motivate. We reward every breath and every drop of sweat. We set goals and help reach them. We encourage to keep going!</p>

      <h2 class="subtitle">Who we are</h2>
      <p>
        We were the inventors of the first Dutch running app and platform more than 10 years ago.
      </p>
      <p>
        sodisp is proudly produced and maintained by Noctilucent BV.
      </p>
      <p>
        We'd love to have your feedback on our website, so feel free to drop us a line.
      </p>

      <p>
        <v-btn color="primary" to="/contact">Contact Us</v-btn>
      </p>
    </div>

  </div>
</template>
<script>
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "About",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>